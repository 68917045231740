import React from "react";
import { Box, Button, Div, Input, Text, Title } from 'components'
import design from "design";

export default class Calculator extends React.Component {
  state = {
    avgReservationsPerDay: 6,
    workingDaysPerWeek: 4.5,
    reservationsPerInvoice: 1,
    total: 0,
    reservations: 0,
    invoices: 0,
    notifications: 0,
  }

  calculate = e => {
    const { avgReservationsPerDay, workingDaysPerWeek, reservationsPerInvoice } = this.state

    let nreservations = 0
    let ninvoices = 0
    let nnotifications = 0
    let cost = 0
    const dayMultiplier = 4 * parseFloat(workingDaysPerWeek)

    nreservations = dayMultiplier * parseFloat(avgReservationsPerDay);
    ninvoices = nreservations/reservationsPerInvoice
    nnotifications = nreservations * 3 + ninvoices * 2

    if (avgReservationsPerDay && workingDaysPerWeek) {
      let costr = 0
      if (nreservations >= 11 && nreservations <= 25) costr += (nreservations - 10) * 0.50
      if (nreservations >= 26 && nreservations <= 50) costr += (15 * 0.50) + (nreservations - 25) * 0.33
      if (nreservations >= 51 && nreservations <= 100) costr += (15 * 0.50) + (25 * 0.33) + (nreservations - 50) * 0.22
      if (nreservations >= 101 && nreservations <= 125) costr += (15 * 0.50) + (25 * 0.33) + (50 * 0.22) + (nreservations - 100) * 0.14
      if (nreservations >= 126) costr += (15 * 0.50) + (25 * 0.33) + (50 * 0.22) + (25 * 0.14) + (nreservations - 125) * 0.09
      cost += costr

      let costi = 0
      if (ninvoices >= 11 && ninvoices <= 25) costi += (ninvoices - 10) * 0.50
      if (ninvoices >= 26 && ninvoices <= 40) costi += (15 * 0.50) + (ninvoices - 25) * 0.30
      if (ninvoices >= 41 && ninvoices <= 80) costi += (15 * 0.50) + (15 * 0.30) + (ninvoices - 40) * 0.18
      if (ninvoices >= 81 && ninvoices <= 100) costi += (15 * 0.50) + (15 * 0.30) + (40 * 0.18) + (ninvoices - 80) * 0.11
      if (ninvoices >= 101) costi += (15 * 0.50) + (15 * 0.30) + (40 * 0.18) + (20 * 0.11) + (ninvoices - 100) * 0.06
      cost += costi

      let costn = 0
      if (nnotifications >= 36 && nnotifications <= 88) costn += (nnotifications - 35) * 0.18
      if (nnotifications >= 89 && nnotifications <= 175) costn += (53 * 0.18) + (nnotifications - 88) * 0.14
      if (nnotifications >= 176 && nnotifications <= 350) costn += (53 * 0.18) + (87 * 0.14) + (nnotifications - 175) * 0.10
      if (nnotifications >= 351 && nnotifications <= 438) costn += (53 * 0.18) + (87 * 0.14) + (175 * 0.10) + (nnotifications - 350) * 0.07
      if (nnotifications >= 439) costn += (53 * 0.18) + (87 * 0.14) + (175 * 0.10) + (88 * 0.07) + (nnotifications - 438) * 0.05
      cost += costn

      this.setState({
        total: this.format(cost),
        reservations: this.format(costr),
        invoices: this.format(costi),
        notifications: this.format(costn)
      })
    } else {
      this.setState({ total: '--', reservations: '--', invoices: '--', notifications: '--' })
    }
  }

  format = value => {
    value = parseFloat(value).toFixed(2);
    value = value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1'");
    return value
  }

  avgReservationsPerDay = e => this.setState({ avgReservationsPerDay: e.target.value })
  workingDaysPerWeek = e => this.setState({ workingDaysPerWeek: e.target.value })
  reservationsPerInvoice = e => this.setState({ reservationsPerInvoice: e.target.value })

  render() {
    return (
      <div>
        <Title mb={[3,5]} fontSize={[3,5]}>6 logiciels, 1 seule facture</Title>
        <Div display={'flex'} _justifyContent={'space-between'}>
          <Div flex={1}>
            <Text mb={[3,4]} fontSize={[2,3]}>• Facturation</Text>
            <Text mb={[3,4]} fontSize={[2,3]}>• Paiement</Text>
            <Text mb={[3,4]} fontSize={[2,3]}>• CRM</Text>
          </Div>
          <Div flex={1}>
            <Text mb={[3,4]} fontSize={[2,3]}>• Site web</Text>
            <Text mb={[3,4]} fontSize={[2,3]}>• Réservation en ligne</Text>
            <Text mb={[3,4]} fontSize={[2,3]}>• Notes</Text>
          </Div>
        </Div>
        <Title mt={[5,6]} mb={[5,6]} fontSize={[3,5]}>Calculez vos frais</Title>

        <Div mb={[3,4]} display={['flex']} justifyContent={'space-between'} alignItems={'center'}>
          <Div flex={[3,4]}>
            <Text mr={2} fontSize={[2,3]}>Nombre moyen de réservations par jour</Text>
          </Div>
          <Div flex={1}>
            <Input textAlign={'right'} value={this.state.avgReservationsPerDay} onChange={this.avgReservationsPerDay} minWidth={120} />
          </Div>
        </Div>

        <Div mb={[3,4]} display={['flex']} justifyContent={'space-between'} alignItems={'center'}>
          <Div flex={[3,4]}>
            <Text mr={2} fontSize={[2,3]}>Nombre de jours de travail par semaine</Text>
          </Div>
          <Div flex={1}>
            <Input textAlign={'right'} value={this.state.workingDaysPerWeek} onChange={this.workingDaysPerWeek} minWidth={120} />
          </Div>
        </Div>

        <Div mb={[3,4]} display={['flex']} justifyContent={'space-between'} alignItems={'center'}>
          <Div flex={[3,4]}>
            <Text mr={2} fontSize={[2,3]}>Nombre de réservations par facture</Text>
          </Div>
          <Div flex={1}>
            <Input textAlign={'right'} value={this.state.reservationsPerInvoice} onChange={this.reservationsPerInvoice} minWidth={120} />
          </Div>
        </Div>

        <Div mb={[3,4]} display={['flex']} justifyContent={'space-between'} alignItems={'center'}>
          <Div flex={[3,4]}>
          </Div>
          <Div flex={1}>
            <Button onClick={this.calculate} minWidth={120} alignSelf={'flex-end'} bg={design.color.primary} color={design.color.white} boxShadow={'primary'}>Estimer</Button>
          </Div>
        </Div>

        <Title mt={[4,5]} mb={[3,5]} fontSize={[3,5]}>Prix estimatif</Title>
        <Div mb={[3,4]} display={'flex'} justifyContent={'space-between'}>
          <Div><Text fontSize={[2,3]}>Réservations</Text></Div>
          <Div><Text fontSize={[2,3]}>CHF {this.state.reservations}</Text></Div>
        </Div>
        <Div mb={[3,4]} display={'flex'} justifyContent={'space-between'}>
          <Div><Text fontSize={[2,3]}>Factures</Text></Div>
          <Div><Text fontSize={[2,3]}>CHF {this.state.invoices}</Text></Div>
        </Div>
        <Div mb={[3,4]} display={'flex'} justifyContent={'space-between'}>
          <Div><Text fontSize={[2,3]}>Notifications</Text></Div>
          <Div><Text fontSize={[2,3]}>CHF {this.state.notifications}</Text></Div>
        </Div>
        <Div mb={[3,4]} display={'flex'} justifyContent={'space-between'}>
          <Div><Text fontSize={[2,3]} fontWeight={700}>Total</Text></Div>
          <Div><Text fontSize={[2,3]} fontWeight={700}>CHF {this.state.total}</Text></Div>
        </Div>
      </div>
    )
  }
}