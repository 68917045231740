import styled from "styled-components";
import React, { Component } from "react";
import design from "../design";
import BareLogo from "../images/logo.svg";
import { Div, Title, Text, Link } from 'components'

const Logo = styled(BareLogo)`
  height: 28px;
  .a {
    fill: #FFF;
    fill: ${design.color.text};
  }
`

// const TempLink = ({ to, children, className, ...rest }) => (<a className={className} href={to} {...rest}>{children}</a>)
const TempLink = ({ to, children, className, ...rest }) => {
  if (to) {
    return (<Link className={className} to={to} {...rest}>{children}</Link>)
  } else {
    return (<a className={className} {...rest}>{children}</a>)
  }
}
//Link
const NavItem = styled(TempLink)`
  // background: gray;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  font-weight: ${({ bold }) => bold ? 700 : 400};
  text-decoration: none;
  margin-right: ${48-10}px;
  ${({ marginLeft }) => marginLeft ? `margin-left: ${marginLeft}px;` : ``}
`
const Nav = styled.nav`
  display: flex;
  ${NavItem} {
    color: ${({ home }) => home ? design.color.white : design.color.text};
  }
  * {
    fill: ${({ home }) => home ? design.color.white : design.color.text} !important;
  }
  align-items: center;
  ${NavItem} {
    padding: 10px;
  }
`

const Caret = styled.div`
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  top: -20px;
  left: calc(50% - 10px);
  z-index: 1;
`;

const DropdownBackground = styled.div`
  transform-origin: 0 0;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
`;
const DropdownSlot = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  perspective: 1000px;
  margin-top: 14px;
`;

class DropdownContainer extends Component {
  render() {
    return (
      <div>
        <Caret />
        <DropdownBackground>{this.props.children}</DropdownBackground>
      </div>
    );
  }
}

const Rounded = styled.span`
  color: ${design.color.white};
  background: ${design.color.primary};
  border-radius: 24px;
  padding: 8px 12px;
  font-size: 12px;
  margin-left: 8px;
  vertical-align: middle;
  display: inline-block;
  font-weight: 700;
`

class BareSolutionsDropdown extends Component {
  render() {
    const { className } = this.props
    return (
      <div className={className}>
        <Div p={5}>
          <Text mb={7} color={'text'} fontSize={5}>A unified digital platform to
            start, run, and grow your solo venture</Text>
          <Link mb={5} to={`/en/finance`}>
            <Title mb={2} fontSize={4}>Billing / Accounting</Title>
            <Text color={'grayDark'} fontSize={2}>Cut your administrative work by half</Text>
          </Link>
          <Link mb={5} to={`/en/schedule`}>
            <Title mb={2} fontSize={4}>Scheduling / Booking</Title>
            <Text color={'grayDark'} fontSize={2}>Bury no-shows, not your customers</Text>
          </Link>
          <Link mb={5} to={`/en/customers`}>
            <Title mb={2} fontSize={4}>Customer Interactions</Title>
            <Text color={'grayDark'} fontSize={2}>Acquire, engage, and retain customers</Text>
          </Link>
          <Link mb={0} to={`/en/payment`}>
            <Title mb={2} fontSize={4}>Online Payments <Rounded>SOON</Rounded></Title>
            <Text color={'grayDark'} fontSize={2}>Get paid fast, every time</Text>
          </Link>
        </Div>
      </div>
    )
  }
}
const SolutionsDropdown = styled(BareSolutionsDropdown)`
  width: 466px;
`

const MouseHelper = styled.div`
  position: absolute;
  width: 466px;
  transform: translateX(-40%);
  height: 24px;
`

class Navigation extends Component {
  state = {
    open: false
  }

  onMouseEnter = e => {
    this.setState({ open: true })
  }

  onMouseLeave = e => {
    this.setState({ open: false })
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.__closeDesktopNav = this.onMouseLeave
    }
  }

  render() {
    return (
      <Nav className={'st'} home={this.props.home}>
        <NavItem marginLeft={-10} to="/en">
          <Div display={['none','block']}>
            <Logo/>
          </Div>
          <Div className={'st'} display={['flex','none']} alignItems={'center'}>
            <Logo /><Title ml={2} fontSize={3}>Stairlin</Title>
          </Div>
        </NavItem>
        <Div display={['none','inline-flex']}>
          <NavItem bold={false} to="/en">Overview</NavItem>
          <NavItem bold={false} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            Solutions
            {this.state.open && (
              <div>
                <MouseHelper />
                <DropdownSlot>
                  <DropdownContainer>
                    <SolutionsDropdown />
                  </DropdownContainer>
                </DropdownSlot>
              </div>
            )}
          </NavItem>
          <NavItem bold={false} to="/en/pricing">Pricing</NavItem>
          <NavItem bold={false} to="/en/support">Support</NavItem>
          <NavItem bold={false} to="/en/learn">Learn</NavItem>
          <NavItem bold={false} to="/en/about">About</NavItem>
        </Div>
      </Nav>
    )
  }
}

export default Navigation
