import {
  Box,







  Br, Button, Div,








  FullWidthBg, Img, Input,




  Link, Text, Title,








  Tooltip
} from 'components'
import { RequestDemo } from 'containers/fr'
import IslandSchedule from "images/BookingBurial8bitcopy.png"
import BareArrow from 'images/btnArrow.svg'
import BareChat from 'images/chat.svg'
import ComfortCottage from "images/ComfortCottage8bitcopy.png"
import IslandCustomers from "images/ConversationsCoppice8bitcopy.png"
import IslandFinance from "images/PaperworkPassagebig8bitcopy.png"
import IslandPayment from "images/PaymentPortal8bitcopy.png"
import StairlinShip from "images/StairlinShip8bitcopy.png"
import Layout from 'layouts/fr'
import React from 'react'
import styled from 'styled-components'
import Clouds from '../images/cloudscopy.png'
import em from '../images/em.jpg'
import BareIllustration from '../images/illustration.svg'
import js from '../images/js.jpg'
import r from '../images/r.jpg'


const PageLink = styled.a`
  color: white;
  text-decoration: underline;
`
const Chat = styled(BareChat)`

`
const Illustration = styled(BareIllustration)`
  //width: 100%;
  overflow: hidden;
  mask g {
    transform: scale(.9705);
  }

  width: 100%;
  height: auto;

  filter: drop-shadow(8px 8px 32px rgba(57,120,153,0.2));
`

const ScrollDown = styled(BareArrow)`
  fill: white;
  padding: 1rem;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  background-color: rgba(119,208,221);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 8px 8px 32px 0px rgba(119,208,221,0.7);
  cursor: pointer;

  @media screen and (max-width: 720px) {
    display: none;
  }
`

const Testimonials = ({ className }) => {

  return (
    <Div tag={'section'} mb={[4,8]}>
      <Title textAlign={'center'} mb={[6]} mt={8} fontSize={[7,8]} display={['none','block']}>Ce que nos clients disent de nous
      </Title>

      <Div tag={'article'} mb={[0,6]}>
        <Div mt={[7,0]} display={['flex']} flexDirection={['column','row']} alignItems={['center','flex-end']}>
          <Div cursor={'pointer'} ml={[0,`42px`]} display={['flex']} flexDirection={['column']}  alignItems={['center','flex-end']}>
            <Div boxShadow={'primaryDark'} backgroundImage={`url(${js})`} backgroundSize={'cover'} alignSelf={['center','flex-end']} width={[96,120]} height={[96,120]} bg={'primaryDark'} borderRadius={5} mb={5} />
            <Title mb={4} fontSize={[6,7]} textAlign={'right'}>Jonathan Shubs</Title>
            <Text mb={[4,0]} fontSize={[4,6]}>Acuponcteur - Lausanne</Text>
          </Div>
          <Div position={'relative'} maxWidth={['none',394]} ml={[0,7]} bg={'primary'} p={4} borderRadius={24}>
            <Div display={['none','block']} position={'absolute'} bottom={0} left={-36}>
              <Chat />
            </Div>
            <Text fontSize={3} color={'white'}>J’utilise Stairlin depuis presque un an maintenant. Mes patients aiment la facilité d'accès. Moi j’adore, car Stairlin réduit mon temps d’administration de moitié et, grâce aux rappels, les rendez-vous manqués font partie du passé. J’ai aussi pu rationaliser mes processus et économiser du temps et de l’argent. Je recommande vivement Stairlin. <PageLink
              href="#" target="_blank">Ma page Stairlin</PageLink></Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'article'} mb={[0,6]}>
        <Div mt={[7,0]} display={['flex']} flexDirection={['column','row-reverse']} alignItems={['center','flex-end']}>
          <Div cursor={'pointer'} mr={[0,`62px`]} display={['flex']} flexDirection={['column']}  alignItems={['center','flex-start']}>
            <Div boxShadow={'primaryDark'} backgroundImage={`url(${r})`} backgroundSize={'cover'} alignSelf={['center','flex-start']} width={[96,120]} height={[96,120]} bg={'primaryDark'} borderRadius={5} mb={5} />
            <Title mb={4} fontSize={[6,7]} textAlign={'left'}>Rebekka</Title>
            <Text mb={[4,0]} fontSize={[4,6]}>Photographe - Zürich</Text>
          </Div>
          <Div position={'relative'} maxWidth={['none',394]} mr={[0,7]} bg={'primary'} p={4} borderRadius={24}>
            <Div display={['none','block']} position={'absolute'} bottom={0} right={-36}>
              <Chat style={{ transform: 'scale(-1, 1)' }} />
            </Div>
            <Text fontSize={3} color={'white'}>Avec Stairlin, nous sommes plus efficients, car les pingpongs d’emails ne sont plus nécessaires, ce qui a amélioré la relation avec nos clients. L’application est soignée, fiable et nous évite de faire des erreurs. Je peux assurer le suivi de mes factures en un coup d’œil et nous n’avons plus perdu la trace d’une seule facture depuis l'arrivée de Stairlin. Je n’ai jamais imaginé que la facturation pouvait devenir un moment agréable. Enfin, j’ai le sentiment d’utiliser les services d’une multinationale, mais avec un support client personnel. Je recommande fortement d’utiliser Stairlin. <PageLink
              href="#" target="_blank">Ma page Stairlin</PageLink></Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'article'} mb={[0,6]}>
        <Div mt={[7,0]} display={['flex']} flexDirection={['column','row']} alignItems={['center','flex-start']}>
          <Div cursor={'pointer'} ml={[0,`70px`]} display={['flex']} flexDirection={['column']}  alignItems={['center','flex-end']}>
            <Div boxShadow={'primaryDark'} backgroundImage={`url(${em})`} backgroundSize={'cover'} alignSelf={['center','flex-end']} width={[96,120]} height={[96,120]} bg={'primaryDark'} borderRadius={5} mb={5} />
            <Title mb={4} fontSize={[6,7]} textAlign={'right'}>Emmanuelle</Title>
            <Text mb={[4,0]} fontSize={[4,6]}>Ostéopathe - Fribourg</Text>
          </Div>
          <Div position={'relative'} maxWidth={['none',394]} ml={[0,7]} bg={'primary'} p={4} borderRadius={24}>
            <Div display={['none','block']} position={'absolute'} bottom={0} left={-36}>
              <Chat />
            </Div>
            <Text fontSize={3} color={'white'}>Stairlin c’est une équipe innovante, simple et hyper efficace qui a libéré mon temps de la lourdeur administrative et logistique. Toujours à l’écoute, ils ont su créer ce dont j’avais besoin sur mesure! Un grand merci à Stairlin. <PageLink
              href="#" target="_blank">Ma page Stairlin</PageLink></Text>
          </Div>
        </Div>
      </Div>

    </Div>
  )
}


const Feature = ({ className, id, title, subtitle, description, list, image, href, inverted }) => {
  // const isDesktop = true
  let desktopStyle = {
    color: inverted ? 'white' : 'text',
    bg: inverted ? 'primary' : 'white',
    buttonColor: inverted ? 'primary' : 'text',
  }
  const img = {
    top: [-56,174],
    right: [12, inverted ? 794+74 : 80],
    width: [96,360],
  }

  return (
    <Div tag={'article'} id={id} mx={[-4,0]} position={'relative'} mb={[120, 0]}>
      <Div position={'absolute'} zIndex={1} top={img.top} right={img.right}>
        <Img shadow src={image} width={img.width} alt={title}/>
      </Div>
      <Div display={['block','flex']} justifyContent={inverted ? 'flex-end' : 'flex-start'}>
        <Box position={'relative'} boxShadow={['black','none']} color={['text',desktopStyle.color]} width={['100%',794]} bg={['white',desktopStyle.bg]} marginTop={69} paddingTop={69} px={[4,6]} py={[4,6]}>
          <Title mb={[4,5]} fontSize={[7,8]}>{title}</Title>
          <Text tag={'h2'} mb={[4,6]} fontSize={[5,8]}>{subtitle}</Text>
          <Text display={['none','block']} mb={[4,6]} fontSize={6}>{description}</Text>
          <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
            {list.map(item => <li><Text fontSize={[4,6]} mb={[3,4]}>• {item}</Text></li>)}
          </ul>
          <Button arrow={`10px`} arrowMargin={`4px`} display={['inline-flex','none']} mt={[2,4]} fontSize={[3,6]} px={[6,6]} py={[2,4]} ml={[-6,0]} color={['primary',desktopStyle.buttonColor]} bg={['transparent','white']} boxShadow={['none',inverted ? 'primaryDark' : 'black']} href={href}>En savoir plus</Button>
          <Button display={['none','inline-flex']} mt={[2,4]} fontSize={[3,6]} px={[6,6]} py={[2,4]} ml={[-6,0]} color={['primary',desktopStyle.buttonColor]} bg={['transparent','white']} boxShadow={['none',inverted ? 'primaryDark' : 'black']} href={href}>En savoir plus</Button>
        </Box>
      </Div>
    </Div>
  )
}

const WhoIsStairlinFor = ({}) => (
  <Div mt={[2,8]} position={'relative'} bg={'primary'} mx={[-36,0]} color={'white'} px={[36,6]} py={[7,8]}>
    <FullWidthBg display={['none','block']}/>
    <Div display={['none','block']} position={'absolute'} top={180} left={144}>
      <Img shadow src={StairlinShip} width={231} alt={''} />
    </Div>
    <Div width={['100%',776]} ml={[0,504]}>
      <Title mb={[4,5]} fontSize={[7,8]}>Pour qui est fait Stairlin ? </Title>
      <Text tag={'h2'} mb={[7,8]} fontSize={[5,8]}>Pour tous les indépendants</Text>

      <Button href={`/fr/who-uses/jonathan-shubs/`} arrow justifyContent={'space-between'} mr={6} mb={5} minWidth={['auto',314]} width={['100%','auto']} bg={'white'} color={'primary'} boxShadow={'primaryDark'} px={[4,4]} py={[4,4]} fontSize={[5,6]}>Acupuncteurs</Button>
      <Button href={`/fr/who-uses/michael-la-delle/`} arrow justifyContent={'space-between'} mr={6} mb={5} minWidth={['auto',314]} width={['100%','auto']} bg={'white'} color={'primary'} boxShadow={'primaryDark'} px={[4,4]} py={[4,4]} fontSize={[5,6]}>Coiffeurs</Button>
      <Button href={`/fr/who-uses/rebekka-wood/`} arrow justifyContent={'space-between'} mr={6} mb={5} minWidth={['auto',314]} width={['100%','auto']} bg={'white'} color={'primary'} boxShadow={'primaryDark'} px={[4,4]} py={[4,4]} fontSize={[5,6]}>Photographes</Button>

      <Button arrow arrowColor={'white'} justifyContent={'space-between'} mr={6} mb={5} minWidth={['auto',314]} width={['100%','auto']} bg={'transparent'} color={'white'} px={[4,4]} py={[4,4]} fontSize={[5,6]}>Et bien plus</Button>

    </Div>
  </Div>
)

const StartOrDemo = ({}) => {
  return (
    <Div position={'relative'}>
      <Div display={['none','block']} position={'absolute'} top={180} right={65}>
        <Img shadow src={ComfortCottage} width={335} alt={''} />
      </Div>
      <Div width={['100%',776]}>
        <Div px={[0]} py={[7,8]}>
          <Title mb={[4,5]} fontSize={[7,8]}>Il est temps d’améliorer votre qualité de vie </Title>
          <Text mb={[7,8]} fontSize={[5,8]}>Les utilisateurs de Stairlin ont plus de temps pour leurs loisirs et leurs familles</Text>
        </Div>
        <Div display={'flex'}>
        <Div width={['100%',314]} mr={6} display={['none','block']}>
            <Title mb={6} fontSize={[5]}>Démarrez maintenant</Title>
            <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                <li><Text fontSize={3} mb={3}>• Installation simple</Text></li>
                <li><Text fontSize={3} mb={3}>• Pas de frais initiaux</Text></li>
                <li><Text fontSize={3} mb={3}>• Pas de carte de crédit requise</Text></li>
                <li><Text fontSize={3} mb={3}>• Pas de frais de résiliation</Text></li>
            </ul>
        </Div>
          <Div mx={[0, 6]} width={['100%',314]}>
            <RequestDemo />
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export default class IndexFr extends React.Component {
  state = {}

  tooltip = (label, top, left) => e => {
    if (!e || !e.target) return
    if (this.state.show && this.state.label === label) return
    const bb = e.target.getBoundingClientRect()
    console.log('ttt', e.target, bb, label)
    // this.setState({ show: true, top: bb.top, left: bb.left, label })
    this.setState({ show: true, top, left, label })
  }

  close = e => {
    this.setState({ show: false })
  }

  scrollDown = () => {

  }

  render() {
    return (
      <Layout gradient={true}>
        <Div mt={[0,6]} mx={[0,-6]} position={'relative'}>
          <Div position={'relative'} className={'illustration'} display={['none','block']}>
            <Illustration />
            <Link
              onMouseEnter={this.tooltip('Interaction client', 223, 350)}
              onMouseMove={this.tooltip('Interaction client', 223, 350)}
              onMouseLeave={this.close} to={'/fr#Customers'} cursor={'pointer'} position={'absolute'} left={322} top={223} width={230} height={181} borderRadius={'50%'} background={'rgba(255,0,0,.3)'} />
            <Link
              onMouseEnter={this.tooltip('Calendrier / Réservations', 320, 670)}
              onMouseMove={this.tooltip('Calendrier / Réservations', 320, 670)}
              onMouseLeave={this.close} to={'/fr#Schedule'} cursor={'pointer'} position={'absolute'} left={677} top={343} width={170} height={131} borderRadius={'50%'} background={'rgba(255,0,0,.3)'} />
            <Link
              onMouseEnter={this.tooltip('Paiement en ligne', 233, 810)}
              onMouseMove={this.tooltip('Paiement en ligne', 233, 810)}
              onMouseLeave={this.close} to={'/fr#Payment'} cursor={'pointer'} position={'absolute'} left={801} top={243} width={182} height={151} borderRadius={'50%'} background={'rgba(255,0,0,.3)'} />
            <Link
              onMouseEnter={this.tooltip('Facturation / Comptabilité', 193, 542)}
              onMouseMove={this.tooltip('Facturation / Comptabilité', 193, 542)}
              onMouseLeave={this.close} to={'/fr#Finance'} cursor={'pointer'} position={'absolute'} left={552} top={193} width={210} height={171} borderRadius={'50%'} background={'rgba(255,0,0,.3)'} />
            <Tooltip show={this.state.show} label={this.state.label} top={this.state.top} left={this.state.left} />
          </Div>
          <Div display={['block','none']} ml={-119} mr={-36}>
            <img style={{ position: 'absolute', left: 58 }} src={StairlinShip} width={102} alt={''} />
            <img alt='clouds' src={Clouds} />
          </Div>

          <Div position={'relative'} margin={'0 auto'} mt={[-220,0]} mb={[120,6]} maxWidth={700} textAlign={['left','center']}>
            <Title color={['white','text']} mb={[6]} fontSize={[7,8]}>Stairlin est une solution tout-en-un pour créer, gérer et développer votre entreprise solo</Title>
            <Text tag={'h2'} display={['none','block']} fontSize={6}>Tous les indépendants rencontrent les mêmes problématiques. Une charge administrative élevée et redondante, des retards de paiements, des rendez-vous manqués et pas assez de temps pour ce qui compte vraiment. La solution ? Stairlin. Essayez maintenant !</Text>
            <Box mx={[-4,0]} mt={[0,6]} bg={['white','transparent']} boxShadow={['black','none']} p={[4,0]}>
              <form action="https://stairlin.com/go" method="get">
                <Div display={['block','flex']}>
                  <Input name="email" fontSize={[4,6]} p={[3,4]} placeholder={`Votre email`} /><Button width={['100%','auto']} type={'submit'} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Démarrer maintenant</Button>
                </Div>
              </form>
              <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Installation simple • Pas de frais initiaux • Pas de carte de crédit requise • Pas de frais de résiliation</Text>
            </Box>
          </Div>

          <Box bg={'white'} mx={[-4,0]} mt={120} mb={120} p={[4,6]}>
            <Title mb={4} fontSize={[6,7]}>Nouvelle fonction vidéo pour les rendez-vous</Title>
            <Text tag={'h2'} display={['block']} fontSize={[4, 6]}>
              Nous sommes heureux de vous proposer dès à présent notre nouvelle application vidéo, pour vos rendez-vous virtuels qui s’intègre dans la panoplie des services de Stairlin.
            </Text>

            <Div textAlign={['center','right']} mt={[6]}>
              <Button
                href={'/fr/update-meet'}
                display={['block', 'inline-block']}
                fontSize={[3,4]}
                px={[3,3]}
                py={[3,3]}
                color="white"
                bg="primary"
                textAlign={["center", "right"]}
              >En savoir plus</Button>
            </Div>
          </Box>

          <Feature
            title={<span>Interaction<Br display={['block','none']} /> client</span>}
            subtitle={'Communiquez avec vos clients où qu’ils soient'}
            description={'Stairlin intègre tous vos canaux de communication dans une seule interface dans le but de vous aider à atteindre, engager et fidéliser vos clients.'}
            list={[
              'Un site web dédié référencé sur Google',
              'Un système de consultation vidéo simple, 100% sécurisé et qui respecte la vie privée.',
              'Vos conversations SMS, WhatsApp, Facebook et email au même endroit',
            ]}
            image={IslandCustomers}
            href={'/fr/customers'}
            id={'Customers'}
            inverted={true}
          />
          <Feature
            title={<span>Calendrier<Br display={['block','none']} /> / Réservations</span>}
            subtitle={'Au revoir rendez-vous manqués, bonjour nouveaux clients !'}
            description={'Réduisez de manière significative vos rendez-vous non honorés grâce aux rappels automatiques. '}
            list={[
              'Réduction significative de vos rendez-vous manqués grâce aux rappels par SMS, email et WhatsApp',
              'Une offre de réservation intuitive et disponible 24/7',
              `Le contrôle de votre calendrier en tout temps. Il s'adapte à vos besoins et est accessible depuis votre téléphone, tablette ou ordinateur`,
            ]}
            image={IslandSchedule}
            href={'/fr/schedule'}
            id={'Schedule'}
            inverted={false}
          />
          <Feature
            title={<span>Facturation<Br display={['block','none']}/> / Comptabilité</span>}
            subtitle={'Réduisez votre charge administrative de moitié'}
            description={'Libérez-vous de la lourdeur du travail administratif grâce aux fonctionnalités intuitives et faites sur mesure pour les entrepreneurs solos. Stairlin vous aide à vous concentrer sur ce qui compte le plus, vos clients !'}
            list={[
              'Génération automatisée de vos factures et reçus',
              'Comptabilité simplifiée',
              'Vue globale sur vos finances',
            ]}
            image={IslandFinance}
            href={'/fr/finance'}
            id={'Finance'}
            inverted={true}
          />
          <Feature
            title={<span>Paiement<Br display={['block','none']} /> en ligne</span>}
            subtitle={'Faites-vous payer rapidement. A chaque fois'}
            description={'Spécialement conçu pour les entreprises solo, le système de paiement Stairlin est intuitif, fiable et simple. Nous faisons tout le travail pour vous afin que vous puissiez commencer à accepter des paiements en un clic de souris.'}
            list={[
              'Rappels personnalisés et automatisés',
              'Recevez immédiatement votre argent sur votre compte bancaire',
              'Contrôlez vos finances avec précision tout en ayant une visibilité globale en temps réel',
            ]}
            image={IslandPayment}
            href={'/fr/payment'}
            id={'Payment'}
            inverted={false}
          />
          <WhoIsStairlinFor />
        </Div>
        <Testimonials />
        <StartOrDemo />

        <ScrollDown onClick={() => scroll(0, window.scrollY+500)} />
      </Layout>
    )
  }
}
