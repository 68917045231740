import { Box, Button, Div, Text, Title } from "components";
import { RequestDemo } from "containers/fr";
import design from "design";
import React from "react";
import styled from "styled-components";

const Absolute = styled.div`
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 48px;
  pointer-events: none;
  * {
    pointer-events: all;
  }
`
const Shadow = styled.div`
  position: fixed;
  z-index: 6;
  background: rgba(0,0,0,.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default class TryOrSchedule extends React.Component {
  state = {
    form: false,
  }

  form = e => {
    this.setState({ form: true })
  }

  close = e => {
    this.setState({ form: false })
  }

  render() {
    let { justCTA = false, linkText, onClick } = this.props
    if (!onClick && !linkText) {
      onClick = this.form
    }
    return (
      <Box boxShadow={[null, 'black']} p={[0, 6]} bg={[null, design.color.white]} display={'flex'} justifyContent={'center'} textAlign={'center'}>

        {this.state.form && (
            <div>
              <Shadow onClick={this.close} />
              <Absolute>
                <Box margin={'0 auto'} width={['80%',500]} bg={'white'} p={[2,4]}>
                  <RequestDemo />
                </Box>
              </Absolute>
            </div>
        )}

        <Div maxWidth={[null, '75%']}>
          {!justCTA && (
            <Div px={[36,0]}>
              <Title mb={[4,6]} fontSize={[6,8]}>Pas encore convaincu ? Faite l'essai et voyez à quoi ressemble l'avenir !</Title>
              <Text fontSize={[3,6]}>Pas de carte de crédit requise.</Text>
            </Div>
          )}
          <Div alignItems={'center'} justifyContent={'center'} display={['block', 'flex']} mt={[6,7]}>
            <Text ml={[0,6]} mt={[5, 0]} mb={[3, 0]} fontSize={[3,6]} fontWeight={2}>ou</Text>
            <Button arrow={18} display={['inline-flex','none']} onClick={onClick} px={[6,6]} py={[2,4]} fontSize={[3,6]} bg={null} color={'primary'}>{linkText ? linkText : `Demander une démonstration`}</Button>
            <Button arrow display={['none','inline-flex']} onClick={onClick} px={[6,6]} py={[2,4]} fontSize={[3,6]} bg={null} color={'primary'}>{linkText ? linkText : `Demander une démonstration`}</Button>
          </Div>
        </Div>
      </Box>
    )

  }
}
