import { Div, FooterLink, FullWidthBg, Root, Text, Title } from 'components';
import React from "react";
import styled from "styled-components";
import { space } from 'styled-system';
import design from "../design";
import BareLogo from "../images/logo.svg";
import MobileNav from './mobileNav.en';
import Navigation from './nav.en';
import "./style.scss";

const FullWidthHeader = styled(Div)`
  display: none;
  position: absolute;
  top: 0;
  left: calc(-100%);
  right: calc(100%);
  bottom: 0;
  background: ${({ bg }) => bg ? bg : design.color.primary};
  width: 300%;
  @media screen and (min-width: 1280px) {
    display: block;
  }
`

const ElBareLogo = ({ className }) => (<BareLogo className={className} />)
const Logo = styled(ElBareLogo)`
  height: 28px;
  .a, path {
    fill: #FFF !important;
  }
  ${space}
`
const Line = styled.div`
  border-top: 1px solid rgba(244, 253, 255, 0.4)
`
const Gradient = styled(Div)`
  background: rgb(57,120,153);
  background: linear-gradient(to bottom,  rgba(57,120,153,1) 0%,rgba(70,175,196,1) 150px,rgba(119,208,221,1) 300px,rgba(238,251,253,1) 700px);
`

export default class IndexEn extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll()
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (typeof window !== 'undefined') {
      if (window.scrollY < 40) {
        document.getElementById('sticky').classList.remove('white')
      } else {
        document.getElementById('sticky').classList.add('white')
      }
      console.log('scroll', window.scrollY)
      // this.setState({scroll: window.scrollY});
    }
  }

  render() {
    const { children, className, gradient } = this.props
    return (
      <Root gradient={gradient} className={className}>
        <Div p={[`24px 36px`,`24px 48px`]}>
          <Div userSelect={'none'} id={'sticky'} maxWidth={1280} margin={'0 auto'} display={'flex'} color={'text'} bg={[gradient ? 'transparent' : '#F4FDFF', 'transparent']} _boxShadow={['none','none']} top={0} left={0} right={0} p={[`8px 36px`,`24px 48px`]} position={['fixed','fixed']} style={{ zIndex:5 }} justifyContent={'space-between'} alignItems='center'>
            <FullWidthHeader display={['none','block']} className={'fullwh'} bg={gradient ? 'transparent' : '#F4FDFF'} />
            <Navigation home={gradient} />
            <Div display={'flex'} zIndex={1}>
              <Div display={['block','none']}>
                <MobileNav home={gradient} />
              </Div>
            </Div>
          </Div>
          {children}
        </Div>
        <footer>
          <Div position={'relative'} color={'white'} bg={'primary'} mt={[4,8]}>
            <FullWidthBg />
            <Div display={['block', 'flex']}>
              <Div p={[36,6]} ml={[0,6]}>
                <Text tag={'span'} display={'inline-block'} lineHeight={1.1} fontWeight={700} position={'relative'} mb={[5,4]} fontSize={6}>
                  <Div tag={'span'} mr={[14,0]} display={'inline-block'} position={['static','absolute']} top={0} left={-36}><Logo mt={[0,`-2px`]} mb={[`-4px`,0]} /></Div>
                  Stairlin
                </Text>
                <Div tag={'address'}>
                  <Text mb={4}>Avenue du Théâtre 1<br/>
                    1005 Lausanne<br/>
                    Switzerland</Text>
                  <Text mb={4}><a href="mailto:info@stairlin.com">info@stairlin.com</a><br/>
                    <a href="tel:+41215391832">+41 (0)21 539 18 32</a></Text>
                </Div>
                <Text>VAT CHE-230.644.686</Text>
              </Div>
              <Div display={['block', 'flex']} p={[36,6]}>
                <Div mx={[0,6]}>
                  <Title mb={[5,4]} fontSize={6}>General</Title>
                  <FooterLink to={'/en/about'} mb={[4,2]} fontSize={3}>About</FooterLink>
                  <FooterLink to={'/en/jobs'} mb={[4,2]} fontSize={3}>Jobs</FooterLink>
                  <FooterLink to={'/en/terms'} mb={[4,2]} fontSize={3}>Terms & Conditions</FooterLink>
                  <FooterLink to={'/en/privacy'} mb={[4,2]} fontSize={3}>Privacy Policy</FooterLink>
                  <FooterLink to={'/en/faq'} mb={7} fontSize={3}>FAQ</FooterLink>
                </Div>
                <Div mx={[0,6]}>
                  <Title mb={[5,4]} fontSize={6}>Merchant</Title>
                  <FooterLink to={'/en/support'} mb={7} fontSize={3}>Support</FooterLink>
                </Div>
                <Div mx={[0,6]}>
                  <Title mb={[5,4]} fontSize={6}>Customer</Title>
                  <FooterLink to={'/en/support'} mb={7} fontSize={3}>Support</FooterLink>
                </Div>
              </Div>
            </Div>
            <Line/>
            <Div px={[36,6]} py={4} display={['block','flex']} justifyContent={'space-between'}>
              <Div>
                © 2020 Stairlin AG
              </Div>
              <Div mt={[4,0]} display={'flex'}>
                <Text mr={6} fontWeight={700}>English</Text>
                <Text><a style={{ color: 'white' }} href={`/fr`}>Français</a></Text>
                {/*<Text>Deutsch</Text>*/}
              </Div>
            </Div>
          </Div>
        </footer>
      </Root>
    )
  }
}
