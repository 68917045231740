import { Box, Br, Button, Div, FullWidthBg, Img, Input, Link, Text, Title, Tooltip } from 'components'
import { RequestDemo } from 'containers/en'
import IslandSchedule from "images/BookingBurial8bitcopy.png"
import BareArrow from 'images/btnArrow.svg'
import BareChat from 'images/chat.svg'
import Clouds from 'images/cloudscopy.png'
import ComfortCottage from "images/ComfortCottage8bitcopy.png"
import IslandCustomers from "images/ConversationsCoppice8bitcopy.png"
import em from 'images/em.jpg'
import BareIllustration from 'images/illustration.svg'
import js from 'images/js.jpg'
import IslandFinance from "images/PaperworkPassagebig8bitcopy.png"
import IslandPayment from "images/PaymentPortal8bitcopy.png"
import r from 'images/r.jpg'
import StairlinShip from "images/StairlinShip8bitcopy.png"
import Layout from 'layouts/en'
import React from 'react'
import styled from 'styled-components'

const PageLink = styled.a`
  color: white;
  text-decoration: underline;
`
const Absolute = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 48px;
  pointer-events: none;
  * {
    pointer-events: all;
  }
`
const Shadow = styled.div`
  position: fixed;
  z-index: 10;
  background: rgba(0,0,0,.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Chat = styled(BareChat)`

`

const Illustration = styled(BareIllustration)`
  overflow: hidden;
  width: 100%;
  height: auto;

  filter: drop-shadow(8px 8px 32px rgba(57,120,153,0.2));

  mask g {
    transform: scale(.9705);
    width: 100%;
    height: auto;
  }
`

const ScrollDown = styled(BareArrow)`
  fill: white;
  padding: 1rem;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  background-color: rgba(119,208,221);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 8px 8px 32px 0px rgba(119,208,221,0.7);
  cursor: pointer;

  @media screen and (max-width: 720px) {
    display: none;
  }
`

const Testimonials = ({ className }) => {

  return (
    <Div tag={'section'} mb={[4,8]}>
      <Title textAlign={'center'} mb={[6]} mt={8} fontSize={[7,8]} display={['none','block']}>Testimonials</Title>

      <Div tag={'article'} mb={[0,6]}>
        <Div mt={[7,0]} display={['flex']} flexDirection={['column','row']} alignItems={['center','flex-end']}>
          <Div cursor={'pointer'} ml={[0,`42px`]} display={['flex']} flexDirection={['column']}  alignItems={['center','flex-end']}>
            <Div boxShadow={'primaryDark'} backgroundImage={`url(${js})`} backgroundSize={'cover'} alignSelf={['center','flex-end']} width={[96,120]} height={[96,120]} bg={'primaryDark'} borderRadius={5} mb={5} />
            <Title tag={'h2'} mb={4} fontSize={[6,7]} textAlign={'right'}>Jonathan Shubs</Title>
            <Text tag={'h3'} mb={[4,0]} fontSize={[4,6]}>Acupuncturist - Lausanne</Text>
          </Div>
          <Div position={'relative'} maxWidth={['none',394]} ml={[0,7]} bg={'primary'} p={4} borderRadius={24}>
            <Div display={['none','block']} position={'absolute'} bottom={0} left={-36}>
              <Chat />
            </Div>
            <Text fontSize={3} color={'white'}>I have been using Stairlin for almost a year now. My patients love the ease of access and the functionality. I love it because it cuts my admin time in half and, because of the reminders, missed appointments have become a thing of the past. It has streamlined my business and has saved me time and money. I strongly recommend it. <PageLink
              href="#" target="_blank">My Stairlin Page</PageLink></Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'article'} mb={[0,6]}>
        <Div mt={[7,0]} display={['flex']} flexDirection={['column','row-reverse']} alignItems={['center','flex-end']}>
          <Div cursor={'pointer'} mr={[0,`42px`]} display={['flex']} flexDirection={['column']}  alignItems={['center','flex-start']}>
            <Div boxShadow={'primaryDark'} backgroundImage={`url(${r})`} backgroundSize={'cover'} alignSelf={['center','flex-start']} width={[96,120]} height={[96,120]} bg={'primaryDark'} borderRadius={5} mb={5} />
            <Title tag={'h2'} mb={4} fontSize={[6,7]} textAlign={'left'}>Rebekka</Title>
            <Text tag={'h3'} mb={[4,0]} fontSize={[4,6]}>Photographer - Zürich</Text>
          </Div>
          <Div position={'relative'} maxWidth={['none',394]} mr={[0,7]} bg={'primary'} p={4} borderRadius={24}>
            <Div display={['none','block']} position={'absolute'} bottom={0} right={-36}>
              <Chat style={{ transform: 'scale(-1, 1)' }} />
            </Div>
            <Text fontSize={3} color={'white'}>With Stairlin we are more efficient because it stopped the email ping pong with our customers, which resulted in an improvement in our customer relationship. The app is very slick, reliable, and it prevents us from making mistakes. I can follow my invoices status at a glance. Since we use Stairlin, we have no longer lost track of a single invoice. I never pictured preparing invoices as a pleasant moment. Finally, I feel like I receive a service from a well-established system, but with personal support. I highly recommend using Stairlin. <PageLink
              href="#" target="_blank">My Stairlin Page</PageLink></Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'article'} mb={[0,6]}>
        <Div mt={[7,0]} display={['flex']} flexDirection={['column','row']} alignItems={['center','flex-start']}>
          <Div cursor={'pointer'} ml={[0,`80px`]} display={['flex']} flexDirection={['column']}  alignItems={['center','flex-end']}>
            <Div boxShadow={'primaryDark'} backgroundImage={`url(${em})`} backgroundSize={'cover'} alignSelf={['center','flex-end']} width={[96,120]} height={[96,120]} bg={'primaryDark'} borderRadius={5} mb={5} />
            <Title tag={'h2'} mb={4} fontSize={[6,7]} textAlign={'right'}>Emmanuelle</Title>
            <Text tag={'h3'} mb={[4,0]} fontSize={[4,6]}>Osteopathe - Fribourg</Text>
          </Div>
          <Div position={'relative'} maxWidth={['none',394]} ml={[0,7]} bg={'primary'} p={4} borderRadius={24}>
            <Div display={['none','block']} position={'absolute'} bottom={0} left={-36}>
              <Chat />
            </Div>
            <Text fontSize={3} color={'white'}>An innovative, simple and hyper efficient team that freed my time from the administrative and logistical burden! They created what I needed by listening to me. Many thanks to Stairlin. <PageLink
              href="#" target="_blank">My Stairlin Page</PageLink></Text>
          </Div>
        </Div>
      </Div>

    </Div>
  )
}


const Feature = ({ className, id, title, subtitle, description, list, image, href, inverted }) => {
  // const isDesktop = true
  let desktopStyle = {
    color: inverted ? 'white' : 'text',
    bg: inverted ? 'primary' : 'white',
    buttonColor: inverted ? 'primary' : 'text',
  }
  const img = {
    top: [-56,174],
    right: [12, inverted ? 794+74 : 80],
    width: [96,360],
  }

  return (
    <Div tag={'article'} id={id} mx={[-4,0]} position={'relative'} mb={[120, 0]}>
      <Div position={'absolute'} zIndex={1} top={img.top} right={img.right}>
        <Img shadow src={image} width={img.width} alt={title}/>
      </Div>
      <Div display={['block','flex']} justifyContent={inverted ? 'flex-end' : 'flex-start'}>
        <Box position={'relative'} boxShadow={['black','none']} color={['text',desktopStyle.color]} width={['100%',794]} bg={['white',desktopStyle.bg]} marginTop={69} paddingTop={69} px={[4,6]} py={[4,6]}>
          <Title mb={[4,5]} fontSize={[7,8]}>{title}</Title>
          <Text tag={'h2'} mb={[4,6]} fontSize={[5,8]}>{subtitle}</Text>
          <Text display={['none','block']} mb={[4,6]} fontSize={6}>{description}</Text>
          <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
            {list.map(item => <li><Text fontSize={[4,6]} mb={[3,4]}>• {item}</Text></li>)}
          </ul>
          <Button arrow={`10px`} arrowMargin={`4px`} display={['inline-flex','none']} mt={[2,4]} fontSize={[3,6]} px={[6,6]} py={[2,4]} ml={[-6,0]} color={['primary',desktopStyle.buttonColor]} bg={['transparent','white']} boxShadow={['none',inverted ? 'primaryDark' : 'black']} href={href}>Learn more</Button>
          <Button display={['none','inline-flex']} mt={[2,4]} fontSize={[3,6]} px={[6,6]} py={[2,4]} ml={[-6,0]} color={['primary',desktopStyle.buttonColor]} bg={['transparent','white']} boxShadow={['none',inverted ? 'primaryDark' : 'black']} href={href}>Learn more</Button>
        </Box>
      </Div>
    </Div>
  )
}

const WhoIsStairlinFor = ({}) => (
  <Div mt={[2,8]} position={'relative'} bg={'primary'} mx={[-36,0]} color={'white'} px={[36,6]} py={[7,8]}>
    <FullWidthBg display={['none','block']}/>
    <Div display={['none','block']} position={'absolute'} top={180} left={144}>
      <Img shadow src={StairlinShip} width={231} alt={''} />
    </Div>
    <Div width={['100%',776]} ml={[0,504]}>
      <Title mb={[4,5]} fontSize={[7,8]}>Who is Stairlin for?</Title>
      <Text tag={'h2'} mb={[7,8]} fontSize={[5,8]}>Any self employed who provides services</Text>

      <Button href={`/en/who-uses/jonathan-shubs/`} arrow justifyContent={'space-between'} mr={6} mb={5} minWidth={['auto',314]} width={['100%','auto']} bg={'white'} color={'primary'} boxShadow={'primaryDark'} px={[4,4]} py={[4,4]} fontSize={[5,6]}>Acupuncturist</Button>
      <Button href={`/en/who-uses/michael-la-delle/`} arrow justifyContent={'space-between'} mr={6} mb={5} minWidth={['auto',314]} width={['100%','auto']} bg={'white'} color={'primary'} boxShadow={'primaryDark'} px={[4,4]} py={[4,4]} fontSize={[5,6]}>Hair stylist</Button>
      <Button href={`/en/who-uses/rebekka-wood/`} arrow justifyContent={'space-between'} mr={6} mb={5} minWidth={['auto',314]} width={['100%','auto']} bg={'white'} color={'primary'} boxShadow={'primaryDark'} px={[4,4]} py={[4,4]} fontSize={[5,6]}>Photographer</Button>

      <Button arrow arrowColor={'white'} justifyContent={'space-between'} mr={6} mb={5} minWidth={['auto',314]} width={['100%','auto']} bg={'transparent'} color={'white'} px={[4,4]} py={[4,4]} fontSize={[5,6]}>And many others</Button>

    </Div>
  </Div>
)

const StartOrDemo = ({}) => {
  return (
    <Div position={'relative'}>
      <Div display={['none','block']} position={'absolute'} top={180} right={65}>
        <Img shadow src={ComfortCottage} width={335} alt={''} />
      </Div>
      <Div width={['100%',776]}>
        <Div px={[0]} pt={[7,8]}>
          <Title mb={[4,5]} fontSize={[7,8]}>Take a left turn today</Title>
          <Text mb={[7,8]} fontSize={[5,8]}>People on Stairlin have more time
            for their family and leisure</Text>
        </Div>
        <Div display={'flex'}>
          <Div width={['100%',314]} mr={6} display={['none','block']}>
            <Title mb={6} fontSize={[5]}>Start Now</Title>
            <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
              <li><Text fontSize={3} mb={3}>• Easy setup</Text></li>
              <li><Text fontSize={3} mb={3}>• Free for starters</Text></li>
              <li><Text fontSize={3} mb={3}>• No credit card required</Text></li>
              <li><Text fontSize={3} mb={3}>• Cancel any time</Text></li>
            </ul>
          </Div>
          <Div mx={[0, 6]} width={['100%',314]}>
            <RequestDemo />
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export default class Index extends React.Component {
  state = {}

  select = lang => e => {
    window.location = `/${lang}`
  }

  tooltip = (label, top, left) => e => {
    if (!e || !e.target) return
    if (this.state.show && this.state.label === label) return
    const bb = e.target.getBoundingClientRect()
    console.log('ttt', e.target, bb, label)
    // this.setState({ show: true, top: bb.top, left: bb.left, label })
    this.setState({ show: true, top, left, label })
  }

  close = e => {
    this.setState({ show: false })
  }

  render() {
    const { languagePopup } = this.props
    return (
      <Layout gradient={true}>
        <Div display={languagePopup ? 'block' : 'none'}>
          <Shadow>
            <Absolute>
              <Box textAlign={'center'} margin={'0 auto'} width={['80%',500]} bg={'white'} p={[3,5]}>
                <Text mb={4} fontSize={[4,5]}>Select one of our supported languages:</Text>
                <Div display={'inline-flex'}>
                  <Button bg={'primary'} boxShadow={'primary'} color={'white'} onClick={this.select('en')}>English</Button>
                  <Button bg={'primary'} boxShadow={'primary'} color={'white'} ml={4} onClick={this.select('fr')}>Français</Button>
                </Div>
              </Box>
            </Absolute>
          </Shadow>
        </Div>

        <Div mt={[0,6]} mx={[0,-6]} position={'relative'}>
          <Div position={'relative'} className={'illustration'} display={['none','block']}>
            <Illustration />
            <Link
              onMouseEnter={this.tooltip('Customer interactions', 223, 322)}
              onMouseMove={this.tooltip('Customer interactions', 223, 322)}
              onMouseLeave={this.close} to={'/en#Customers'} cursor={'pointer'} position={'absolute'} left={322} top={223} width={230} height={181} borderRadius={'50%'} background={'rgba(255,0,0,.3)'} />
            <Link
              onMouseEnter={this.tooltip('Scheduling / Booking', 320, 680)}
              onMouseMove={this.tooltip('Scheduling / Booking', 320, 680)}
              onMouseLeave={this.close} to={'/en#Schedule'} cursor={'pointer'} position={'absolute'} left={677} top={343} width={170} height={131} borderRadius={'50%'} background={'rgba(255,0,0,.3)'} />
            <Link
              onMouseEnter={this.tooltip('Online payments', 233, 820)}
              onMouseMove={this.tooltip('Online payments', 233, 820)}
              onMouseLeave={this.close} to={'/en#Payment'} cursor={'pointer'} position={'absolute'} left={801} top={243} width={182} height={151} borderRadius={'50%'} background={'rgba(255,0,0,.3)'} />
            <Link
              onMouseEnter={this.tooltip('Billing / Accounting', 193, 572)}
              onMouseMove={this.tooltip('Billing / Accounting', 193, 572)}
              onMouseLeave={this.close} to={'/en#Finance'} cursor={'pointer'} position={'absolute'} left={552} top={193} width={210} height={171} borderRadius={'50%'} background={'rgba(255,0,0,.3)'} />
            <Tooltip show={this.state.show} label={this.state.label} top={this.state.top} left={this.state.left} />
          </Div>
          <Div display={['block','none']} ml={-119} mr={-36}>
            <img style={{ position: 'absolute', left: 58 }} src={StairlinShip} width={102} alt={''} />
            <img alt='clouds' src={Clouds} />
          </Div>

          <Div position={'relative'} margin={'0 auto'} mt={[-220,0]} mb={[120,6]} maxWidth={700} textAlign={['left','center']}>
            <Title color={['white','text']} mb={[6]} fontSize={[7,8]}>Stairlin is a one stop shop to start, manage and grow your solo venture</Title>
            <Text tag={'h2'} display={['none','block']} fontSize={6}>Every self-employed run into the same fundamental problems. Endless administrative work, late payments, no-shows, buried under email, and not enough time for what matters. The solution? Stairlin. Try it now!</Text>
            <Box mx={[-4,0]} mt={[0,6]} bg={['white','transparent']} boxShadow={['black','none']} p={[4,0]}>
              <form action="https://stairlin.com/go" method="get">
                <Div display={['block','flex']}>
                  <Input name="email" fontSize={[4,6]} p={[3,4]} placeholder={`Your email address`} /><Button width={['100%','auto']} type={'submit'} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Get Started</Button>
                </Div>
              </form>
              <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Easy setup • Free for starters • No credit card required • Cancel any time</Text>
            </Box>
          </Div>

          <Box bg={'white'} mx={[-4,0]} mt={120} mb={120} p={[4,6]}>
            <Title mb={4} fontSize={[6,7]}>New Video Feature for Appointments</Title>
            <Text tag={'h2'} display={['block']} fontSize={[4, 6]}>
              Stairlin is happy to offer you now its new video application, for your virtual appointments. So you can connect with your customers wherever they are.
            </Text>

            <Div textAlign={['center','right']} mt={[6]}>
              <Button
                href={'/en/update-meet'}
                display={['block', 'inline-block']}
                fontSize={[3,4]}
                px={[3,3]}
                py={[3,3]}
                color="white"
                bg="primary"
                textAlign={["center", "right"]}
              >Discover now!</Button>
            </Div>
          </Box>

          <Feature
            title={'Customer interactions'}
            subtitle={'Connect with your customer wherever they are.'}
            description={'Stairlin integrates all your communication channels in one simple and seamless interface to help you reach, engage and retain customers.'}
            list={[
              'Pick up conversations via all the major channels (what’sapp, facebook etc..)',
              'A simple video consultation system, 100% secure, and which respects your privacy.',
              'A beautiful home for your business with our dedicated website',
            ]}
            image={IslandCustomers}
            href={'/en/customers'}
            id={'Customers'}
            inverted={true}
          />
          <Feature
            title={'Scheduling / Booking'}
            subtitle={'Goodbye no-shows, hello new customers'}
            description={'Spend less time arranging appointments with our automated booking system : show up-to-date availability, cancellations, modifications and set up automatic reminders and confirmation.'}
            list={[
              'Significantly reduce no-shows with automated SMS, email and WhatsApp reminders',
              'Provide your customers with a flexible 24/7 online booking experience',
              'Control your calendar at all time. Fully adaptable to your needs, it is synchronized everywhere: phone, tablet or computer']}
            image={IslandSchedule}
            href={'/en/schedule'}
            id={'Schedule'}
            inverted={false}
          />
          <Feature
            title={'Billing / Accounting'}
            subtitle={'Cut your administrative work by half'}
            description={'Free yourself from admin some with clever, easy-to-use features, built for self-employed. Stairlin helps you focus on what matters the most, your clients.'}
            list={[
              'Automatic invoices and receipts generation',
              'Your bookkeeping simplified',
              'Bird’s-eye view of your finances',
            ]}
            image={IslandFinance}
            href={'/en/finance'}
            id={'Finance'}
            inverted={true}
          />
          <Feature
            title={<span>Online<Br display={['block','none']}/> payments</span>}
            subtitle={'Get paid fast. Everytime'}
            description={'Specifically designed for solo preneurs, Starlin payment system is intuitive, reliable and easy. Stairlin is a all-in-one set up, we do all the work for you so you can start taking payments with a click of the mouse.'}
            list={[
              'Payment schedules and reminders',
              'Get your money in your bank account instantly',
              'Simple and effective customer noteControl your finances with accuracy and real-time global visibility.',
            ]}
            image={IslandPayment}
            href={'/en/payment'}
            id={'Payment'}
            inverted={false}
          />
          <WhoIsStairlinFor />
        </Div>
        <Testimonials />
        <StartOrDemo />

        <ScrollDown onClick={() => scroll(0, window.scrollY+500)} />
      </Layout>
    )
  }
}
