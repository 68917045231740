import React from "react";
import { Box, Button, Div, Input, Text, Title } from 'components'
import design from "design";

export default ({ }) => (
  <form target="_blank" method="post" action="https://stairlin.us14.list-manage.com/subscribe/post?u=d61bf0db69f16c103c6148607&amp;id=58d0170f73">
    <Title mb={6} fontSize={[5]}>Programmez une démonstration gratuite</Title>
    <Input name={`LNAME`} fontSize={[14,18]} mb={4} placeholder={'Nom'} />
    <Input name={`FNAME`} fontSize={[14,18]} mb={4} placeholder={'Prénom'} />
    <Input name={`EMAIL`} fontSize={[14,18]} mb={4} placeholder={'Email'} />
    <Input name={`MMERGE4`} fontSize={[14,18]} mb={4} placeholder={'Téléphone'} />
    <Button type={`submit`} textAlign={'center'} width={['100%']} bg={'primary'} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3]} fontSize={[3]}>Programmer une démonstration</Button>
  </form>
)