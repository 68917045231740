import React from 'react'
import IndexFr from './index.fr'
import IndexEn from './index.en'
import { pickLocale } from 'utils'

export default class MainIndex extends React.Component {
  state = {
    loaded: false,
    locale: "",
  }

  componentDidMount() {
    console.log('NODE_ENV', process.env.NODE_ENV)

    const supportedLocales = ['en','fr']
    const locale = pickLocale(supportedLocales)

    this.setState({
      loaded: true,
      locale: locale,
    })
  }

  render() {
    if (!this.state.loaded) {
      return ""
    }

    switch (this.state.locale) {
      case 'fr':
        return (
          <IndexFr />
        )
      case 'en':
        return (
          <IndexEn />
        )
      default:
        return (
          <IndexEn languagePopup />
        )
    }
  }
}
